
import { Vue, Component } from "vue-property-decorator";
import admin from "@/store/modules/AdminModule";
import { AdminService } from "@/api/AdminServiceApi";

@Component
export default class DashboardNavbar extends Vue {
  async logout() {
    await AdminService.Logout();
    admin.clear();
    await this.$router.push("/admin/login");
  }
}
